import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink, graphql, PageProps } from "gatsby"
import { ChevronRightIcon } from "@chakra-ui/icons"

import Layout from "../components/layout"
import HomeSeries from "../components/homeSeries"
import { IHomeSeries } from "../components/series"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

interface HomeSeriesProps extends PageProps {
  data: {
    series: IHomeSeries
  }
}

function HomeSeriesTemplate({ data, location }: HomeSeriesProps): JSX.Element {
  const { series } = data

  return (
    <Layout>
      <SEO
        meta={{
          description: series.description.childMarkdownRemark.excerpt,
          image: series.featuredExterior.gatsbyImageData.images.fallback.src,
          imageAlt: series.featuredExterior.description,
        }}
        title={`${series.name} | Sundog Homes`}
        url={location.href}
      />
      <Flex maxW="95vw" mx="auto" py={6} w="1200px">
        <Breadcrumb separator={<ChevronRightIcon />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={GatsbyLink} to="/home-plans/">
              All Plans
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              maxW={{
                base: `calc(95vw - 140px)`,
                xl: `calc(1200px - 100px)`,
              }}
              overflowX="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {series.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex align="center" justify="center">
        <HomeSeries filter="all" series={series} />
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    series: contentfulHomeSeries(name: { eq: $name }) {
      ...HomeSeries
    }
  }
`

export default HomeSeriesTemplate
